@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: "Noto Sans", system-ui, sans-serif;
    }

    .btn {
        @apply inline-flex items-center justify-center h-10 px-4 bg-brand rounded-full shadow text-sm font-bold disabled:opacity-60 disabled:cursor-not-allowed;
    }

    .btn.outlined {
        @apply bg-transparent border border-brand text-brand;
    }

    .text-field {
        @apply flex items-center w-full h-10 px-4 bg-grey-200 outline-brand rounded transition-all;
    }

    .spinner {
        @apply inline-flex w-10 h-10 border-4 border-brand border-b-transparent rounded-full animate-spin;
    }

    .btn .spinner {
        @apply w-6 h-6 border-4 border-white border-b-transparent;
    }
}

.bg-gradient-main {
    @apply bg-gradient-to-r from-[#312F3E] to-[#6A4048] text-white;
}

.perspective:hover .menu-btn {
    @apply text-brand;
}

.perspective:hover .menu-btn svg {
    @apply fill-brand;
}

.perspective:hover .menu {
    @apply flex;
    animation: scale 200ms linear;
    transform-origin: top center;
}

@keyframes scale {
    from {
        transform: scale(0.5);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.menu-btn {
    @apply inline-flex items-center;
}

.menu {
    @apply absolute -inset-x-32 z-40 hidden;
}

.menu-body {
    @apply shadow-menu mt-4 flex flex-col rounded-xl bg-white p-4 text-black;
}

.menu-item {
    @apply flex items-start gap-4 rounded p-4;
}

.menu-item:hover {
    @apply bg-brand bg-opacity-30;
}

.animate-mobile-menu {
    animation: from-left 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes from-left {
    from {
        left: 100%;
    }
    to {
        left: 0%;
    }
}

@layer base {
    .increment-btn {
        @apply inline-flex items-center justify-center w-8 h-8 bg-grey-200 disabled:cursor-not-allowed disabled:text-grey-500;
    }

    .preview-img {
        @apply max-h-24 p-2 aspect-square object-contain object-center cursor-pointer bg-grey-200 rounded transition-all;
    }

    .preview-img[data-active="true"] {
        @apply border-2 border-brand;
    }
}

.payment-mode {
    @apply inline-flex items-center justify-center h-10 px-4 bg-grey-200 rounded shadow transition-all;
}

.payment-mode[data-active="true"] {
    @apply bg-brand;
}

.btn > .spinner-border {
    @apply w-6 h-6;
}

.radio-wrapper {
    @apply inline-flex items-center gap-2 cursor-pointer;
}

.radio {
    @apply inline-flex items-center justify-center w-5 h-5 border-4 border-grey-200 rounded-full transition-all;
}

.radio[data-active="true"] {
    @apply bg-brand;
}

.radio-check {
    @apply w-3 h-3 bg-brand rounded-full;
}
